<template>
  <div id="forgetpassword">
    <Header></Header>
    <div class="content-wrapper">
      <div class="breadcrumb-wrap bg-f br-1">
        <div class="overlay bg-black op-9"></div>
        <img :src="shape1" alt="Image" class="br-shape-1" />
        <img :src="shape2" alt="Image" class="br-shape-2" />
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="breadcrumb-title">
                <h2>Forgot Password</h2>
                <ul class="breadcrumb-menu list-style">
                  <!--<li><a href="index-2.html">Home </a></li>-->
                  <router-link to="/" style="color: white"
                    >Home &nbsp;&rarr;</router-link
                  >
                  <li>Forgot Password</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="login-wrap pt-100 pb-100">
        <img :src="shape1" alt="Image" class="login-shape-1" />
        <img :src="shape2" alt="Image" class="login-shape-2" />
        <div class="container">
          <div class="row">
            <div
              class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2"
            >
              <div class="login-form">
                <div class="login-header bg-green">
                  <h2 class="text-center mb-0">Forgot Password</h2>
                </div>
                <div class="login-body">
                  <form
                    class="form-wrap"
                    action="#"
                    @submit.prevent="forgetPassword"
                  >
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label for="email">E-mail</label>
                          <input
                            id="email"
                            name="email"
                            v-model="form.email"
                            type="email"
                            placeholder="Email Address*"
                            required
                          />
                        </div>
                      </div>
                      <span class="text-danger">{{ message }}</span>
                      <div class="col-lg-12">
                        <div class="form-group mb-0">
                          <button
                            class="btn style1 w-100 d-block"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import { sendPasswordResetMail } from "@/store/api";
//import Mainslider from './layouts/Mainslider.vue';
export default {
  name: "forgetpassword",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      form: {
        email: "",
      },
      message: "",
    };
  },
  methods: {
    forgetPassword() {
      sendPasswordResetMail(this.form).then((res) => {
        console.log(res.data);
        if (res.data.success === true) {
          this.$router.push(`/change-password?email=${this.form.email}`);
        } else if (res.data.message && res.data.message === "User not found") {
          this.message = "User not registered.";
        }
      });
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>
